export default {
  translation: {
    common: {
      delete: 'Hapus',
      deleteModalTitle: 'Apakah kamu yakin ingin menghapus item ini?',
      ok: 'Ya',
      cancel: 'Tidak',
      total: 'Total',
      rename: 'Ganti Nama',
      name: 'Nama',
      save: 'Simpan',
      namePlaceholder: 'Silakan masukkan nama',
      next: 'Berikutnya',
      create: 'Buat',
      edit: 'Edit',
      upload: 'Unggah',
      english: 'Inggris',
      chinese: 'Bahasa Mandarin Sederhana',
      traditionalChinese: 'Bahasa Mandarin Tradisional',
      language: 'Bahasa',
      languageMessage: 'Silakan masukkan bahasa kamu!',
      languagePlaceholder: 'pilih bahasa kamu',
      copy: 'Salin',
      copied: 'Disalin',
      comingSoon: 'Segera Hadir',
      download: 'Unduh',
      close: 'Tutup',
      preview: 'Pratinjau',
      move: 'Pindahkan',
      warn: 'Peringatan',
    },
    login: {
      login: 'Masuk',
      signUp: 'Daftar',
      loginDescription: 'Kami sangat senang melihatmu lagi!',
      registerDescription: 'Senang menyambutmu di sini!',
      emailLabel: 'Email',
      emailPlaceholder: 'Silakan masukkan email',
      passwordLabel: 'Kata Sandi',
      passwordPlaceholder: 'Silakan masukkan kata sandi',
      rememberMe: 'Ingat saya',
      signInTip: 'Tidak punya akun?',
      signUpTip: 'Sudah punya akun?',
      nicknameLabel: 'Nama Panggilan',
      nicknamePlaceholder: 'Silakan masukkan nama panggilan',
      register: 'Buat akun',
      continue: 'Lanjutkan',
      title: 'Mulai membangun asisten pintar kamu.',
      description:
        'Daftar gratis untuk menjelajahi teknologi RAG terbaik. Buat basis pengetahuan dan AI untuk memperkuat bisnismu.',
      review: 'dari 500+ ulasan',
    },
    header: {
      knowledgeBase: 'Basis Pengetahuan',
      chat: 'Chat',
      register: 'Daftar',
      signin: 'Masuk',
      home: 'Beranda',
      setting: 'Pengaturan Pengguna',
      logout: 'Keluar',
      fileManager: 'Manajemen File',
      flow: 'Agen',
      search: 'Cari',
    },
    knowledgeList: {
      welcome: 'Selamat datang kembali',
      description: 'Basis pengetahuan mana yang akan kita gunakan hari ini?',
      createKnowledgeBase: 'Buat basis pengetahuan',
      name: 'Nama',
      namePlaceholder: 'Silakan masukkan nama!',
      doc: 'Dokumen',
      searchKnowledgePlaceholder: 'Cari',
    },
    knowledgeDetails: {
      dataset: 'Dataset',
      testing: 'Pengujian Pengambilan',
      files: 'file',
      configuration: 'Konfigurasi',
      name: 'Nama',
      namePlaceholder: 'Silakan masukkan nama!',
      doc: 'Dokumen',
      datasetDescription:
        '😉 Pertanyaan dan jawaban hanya dapat dijawab setelah parsing berhasil.',
      addFile: 'Tambah file',
      searchFiles: 'Cari file kamu',
      localFiles: 'File lokal',
      emptyFiles: 'Buat file kosong',
      webCrawl: 'Web Crawl',
      chunkNumber: 'Jumlah Chunk',
      uploadDate: 'Tanggal Unggah',
      chunkMethod: 'Metode Chunk',
      enabled: 'Aktif',
      disabled: 'Nonaktif',
      action: 'Aksi',
      parsingStatus: 'Status Parsing',
      processBeginAt: 'Proses Dimulai Pada',
      processDuration: 'Durasi Proses',
      progressMsg: 'Pesan Progres',
      testingDescription:
        'Langkah terakhir! Setelah sukses, serahkan sisanya ke S2RE Hydra.',
      similarityThreshold: 'Ambang Similaritas',
      similarityThresholdTip:
        "Kami menggunakan skor similaritas hibrida untuk mengevaluasi jarak antara dua baris teks. Ini menggabungkan similaritas kata kunci dan similaritas kosinus vektor. Jika similaritas antara query dan chunk kurang dari ambang ini, chunk tersebut akan difilter.",
      vectorSimilarityWeight: 'Bobot Similaritas Kata Kunci',
      vectorSimilarityWeightTip:
        "Kami menggunakan skor similaritas hibrida untuk mengevaluasi jarak antara dua baris teks. Ini menggabungkan similaritas kata kunci dan similaritas kosinus vektor atau skor rerank (0~1). Jumlah kedua bobot ini adalah 1.0.",
      testText: 'Teks Uji',
      testTextPlaceholder: 'Silakan masukkan pertanyaan kamu!',
      testingLabel: 'Pengujian',
      similarity: 'Similaritas Hibrida',
      termSimilarity: 'Similaritas Istilah',
      vectorSimilarity: 'Similaritas Vektor',
      hits: 'Hit',
      view: 'Lihat',
      filesSelected: 'File Dipilih',
      upload: 'Unggah',
      run: 'Jalankan',
      runningStatus0: 'BELUM DIMULAI',
      runningStatus1: 'Parsing',
      runningStatus2: 'DIBATALKAN',
      runningStatus3: 'SUKSES',
      runningStatus4: 'GAGAL',
      pageRanges: 'Rentang Halaman',
      pageRangesTip:
        'rentang halaman: Tentukan rentang halaman yang perlu di-parse. Halaman yang tidak termasuk dalam rentang ini akan diabaikan.',
      fromPlaceholder: 'dari',
      fromMessage: 'Nomor halaman awal hilang',
      toPlaceholder: 'ke',
      toMessage: 'Nomor halaman akhir hilang (tidak termasuk)',
      layoutRecognize: 'Pengenalan Layout',
      layoutRecognizeTip:
        'Gunakan model visual untuk analisis layout agar dapat mengidentifikasi struktur dokumen dengan lebih baik, menemukan di mana judul, blok teks, gambar, dan tabel berada. Tanpa fitur ini, hanya teks biasa dari PDF yang dapat diperoleh.',
      taskPageSize: 'Ukuran Halaman Tugas',
      taskPageSizeMessage: 'Silakan masukkan ukuran halaman tugas kamu!',
      taskPageSizeTip: 'Jika menggunakan pengenalan layout, file PDF akan dibagi menjadi grup-grup yang berurutan. Analisis layout akan dilakukan secara paralel antar grup untuk meningkatkan kecepatan pemrosesan. "Ukuran halaman tugas" menentukan ukuran grup. Semakin besar ukuran halaman, semakin kecil kemungkinan teks berkelanjutan antar halaman terbagi ke chunk yang berbeda.',
      addPage: 'Tambah halaman',
      greaterThan: 'Nilai saat ini harus lebih besar dari ke!',
      greaterThanPrevious:
        'Nilai saat ini harus lebih besar dari sebelumnya ke!',
      selectFiles: 'Pilih file',
      changeSpecificCategory: 'Ubah kategori spesifik',
      uploadTitle: 'Klik atau seret file ke area ini untuk mengunggah',
      uploadDescription:
        'Mendukung unggahan tunggal atau massal. Dilarang keras mengunggah data perusahaan atau file terlarang lainnya.',
      chunk: 'Chunk',
      bulk: 'Massal',
      cancel: 'Batal',
      rerankModel: 'Model Rerank',
      rerankPlaceholder: 'Silakan pilih',
      rerankTip: 'Jika kosong, menggunakan embeddings dari query dan chunks untuk menghitung similaritas kosinus vektor. Jika tidak, menggunakan skor rerank sebagai pengganti similaritas kosinus vektor.',
      topK: 'Top-K',
      topKTip: 'K chunk akan diproses oleh model rerank.',
      delimiter: 'Delimiter',
      html4excel: 'Excel ke HTML',
      html4excelTip: 'Excel akan di-parse menjadi tabel HTML atau tidak. Jika FALSE, setiap baris di Excel akan dibentuk sebagai chunk.',
    },
    knowledgeConfiguration: {
      titleDescription:
        'Perbarui detail basis pengetahuan kamu terutama metode parsing di sini.',
      name: 'Nama basis pengetahuan',
      photo: 'Foto basis pengetahuan',
      description: 'Deskripsi',
      language: 'Bahasa',
      languageMessage: 'Silakan masukkan bahasa kamu!',
      languagePlaceholder: 'Silakan masukkan bahasa kamu!',
      permissions: 'Izin',
      embeddingModel: 'Model Embedding',
      chunkTokenNumber: 'Jumlah Token Chunk',
      chunkTokenNumberMessage: 'Jumlah token chunk diperlukan',
      embeddingModelTip:
        "Model embedding yang digunakan untuk embedding chunks. Tidak dapat diubah setelah basis pengetahuan memiliki chunks. Kamu perlu menghapus semua chunks jika ingin mengubahnya.",
      permissionsTip:
        "Jika izinnya 'Tim', semua anggota tim dapat memanipulasi basis pengetahuan.",
      chunkTokenNumberTip:
        'Ini menentukan jumlah token sebuah chunk secara kasar.',
      chunkMethod: 'Metode Chunk',
      chunkMethodTip: 'Instruksi ada di sebelah kanan.',
      upload: 'Unggah',
      english: 'Inggris',
      chinese: 'Cina',
      indonesia: 'Indonesia',
      embeddingModelPlaceholder: 'Silakan pilih model embedding',
      chunkMethodPlaceholder: 'Silakan pilih metode chunk',
      save: 'Simpan',
      me: 'Hanya saya',
      team: 'Tim',
      cancel: 'Batal',
      methodTitle: 'Deskripsi Metode Chunking',
      methodExamples: 'Contoh',
      methodExamplesDescription:
        'Screenshot berikut disajikan untuk memudahkan pemahaman.',
      dialogueExamplesTitle: 'Contoh Dialog',
      methodEmpty:
        'Ini akan menampilkan penjelasan visual dari kategori basis pengetahuan',
      book: `<p>Format file yang didukung adalah <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
Karena buku panjang dan tidak semua bagiannya berguna, jika berupa PDF, silakan atur <i>rentang halaman</i> untuk setiap buku agar menghilangkan efek negatif dan menghemat waktu komputasi untuk analisis.</p>`,
      laws: `<p>Format file yang didukung adalah <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
Dokumen hukum memiliki format penulisan yang sangat ketat. Kami menggunakan fitur teks untuk mendeteksi titik pemisah.
</p><p>
Granularitas chunk konsisten dengan 'ARTIKEL', dan semua teks tingkat atas akan disertakan dalam chunk.
</p>`,
      manual: `<p>Hanya <b>PDF</b> yang didukung.</p><p>
Kami mengasumsikan manual memiliki struktur seksi hierarkis. Kami menggunakan judul seksi terendah sebagai pivot untuk memotong dokumen.
Jadi, gambar dan tabel di seksi yang sama tidak akan dipotong terpisah, dan ukuran chunk mungkin besar.
</p>`,
      naive: `<p>Format file yang didukung adalah <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p>
<p>Metode ini menerapkan cara sederhana untuk chunking file:</p>
<p>
<li>Teks yang berurutan akan dipotong menjadi bagian-bagian menggunakan model deteksi visual.</li>
<li>Selanjutnya, bagian-bagian berurutan ini digabungkan menjadi chunks yang jumlah tokennya tidak lebih dari 'Jumlah Token'.</li></p>`,
      paper: `<p>Hanya file <b>PDF</b> yang didukung.</p><p>
Jika model kami bekerja dengan baik, paper akan dipotong berdasarkan seksi-seksinya, seperti <i>abstract, 1.1, 1.2</i>, dll.</p><p>
Keuntungannya adalah LLM dapat merangkum konten seksi terkait dalam paper dengan lebih baik, menghasilkan jawaban yang lebih komprehensif yang membantu pembaca memahami paper tersebut dengan lebih baik.
Kekurangannya adalah meningkatkan konteks percakapan LLM dan menambah biaya komputasi, jadi selama percakapan, kamu bisa mempertimbangkan untuk mengurangi pengaturan ‘<b>topN</b>’.
</p>`,
      presentation: `<p>Format file yang didukung adalah <b>PDF</b>, <b>PPTX</b>.</p><p>
Setiap halaman akan diperlakukan sebagai chunk. Dan thumbnail setiap halaman akan disimpan.</p><p>
<i>Semua file PPT yang kamu unggah akan otomatis di-chunk menggunakan metode ini, tidak perlu mengatur setiap file PPT.</i></p>`,
      qa: `
<p>
Metode chunk ini mendukung format file <b>EXCEL</b> dan <b>CSV/TXT</b>.
</p>
<li>
Jika file berformat <b>Excel</b>, harus terdiri dari dua kolom tanpa header: satu untuk pertanyaan dan satu lagi untuk jawaban, dengan kolom pertanyaan mendahului kolom jawaban. Banyak sheet diperbolehkan asalkan kolomnya tersusun dengan benar.
</li>
<li>
Jika file berformat <b>CSV/TXT</b>, harus dikodekan UTF-8 dengan TAB digunakan sebagai delimiter untuk memisahkan pertanyaan dan jawaban.
</li>
<p>
<i>
Baris teks yang tidak mengikuti aturan di atas akan diabaikan, dan setiap pasangan Q&A akan dianggap sebagai chunk yang berbeda.
</i>
</p>
      `,
      resume: `<p>Format file yang didukung adalah <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.
</p><p>
Resume hadir dalam berbagai format, seperti kepribadian seseorang, tetapi kami sering harus mengaturnya menjadi data terstruktur yang mudah dicari.
</p><p>
Alih-alih memotong resume, kami mem-parse resume menjadi data terstruktur. Sebagai HR, kamu bisa memasukkan semua resume yang kamu punya, lalu kamu bisa mendaftar semua kandidat yang memenuhi kualifikasi hanya dengan berbicara dengan <i>'S2RE Hydra'</i>.
</p>
      `,
      table: `<p>Format file <b>EXCEL</b> dan <b>CSV/TXT</b> didukung.</p><p>
Berikut beberapa tips:
<ul>
<li>Untuk file csv atau txt, delimiter antar kolom adalah <em><b>TAB</b></em>.</li>
<li>Baris pertama harus adalah header kolom.</li>
<li>Header kolom harus berupa istilah yang bermakna agar LLM kami bisa memahaminya.
Sebaiknya daftarkan beberapa sinonim menggunakan garis miring <i>'/'</i> sebagai pemisah, dan lebih baik lagi jika
menyebutkan nilai menggunakan tanda kurung seperti <i>'gender/sex(male, female)'</i>.
<p>
Berikut beberapa contoh untuk header:
<ol>
    <li>supplier/vendor<b>'TAB'</b>color(yellow, red, brown)<b>'TAB'</b>gender/sex(male, female)<b>'TAB'</b>size(M,L,XL,XXL)</li>
    <li>姓名/名字<b>'TAB'</b>电话/手机/微信<b>'TAB'</b>最高学历（高中，职高，硕士，本科，博士，初中，中技，中专，专科，专升本，MPA，MBA，EMBA）</li>
</ol>
</p>
</li>
<li>Setiap baris dalam tabel akan diperlakukan sebagai chunk.</li>
</ul>`,
      picture: `
<p>File gambar didukung. Video akan segera hadir.</p><p>
Jika gambar memiliki teks di dalamnya, OCR akan diterapkan untuk mengekstrak teks sebagai deskripsi teksnya.
</p><p>
Jika teks yang diekstrak oleh OCR tidak cukup, LLM visual digunakan untuk mendapatkan deskripsinya.
</p>
      `,
      one: `
<p>Format file yang didukung adalah <b>DOCX, EXCEL, PDF, TXT</b>.
</p><p>
Untuk dokumen, akan diperlakukan sebagai satu chunk utuh, tanpa dipotong sama sekali.
</p><p>
Jika kamu ingin merangkum sesuatu yang membutuhkan semua konteks sebuah artikel dan panjang konteks LLM yang dipilih mencakup panjang dokumen, kamu bisa mencoba metode ini.
</p>
      `,
      knowledgeGraph: `<p>Format file yang didukung adalah <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b></p>
<p>Setelah file di-chunk, digunakan chunks untuk mengekstrak graph pengetahuan dan mind map dari seluruh dokumen. Metode ini menerapkan cara sederhana untuk chunking file:
Teks berurutan akan dipotong menjadi potongan-potongan yang masing-masing sekitar 512 jumlah token.</p>
<p>Selanjutnya, chunks akan dikirim ke LLM untuk mengekstrak node dan hubungan dari graph pengetahuan, serta mind map.</p>

<p>Perhatikan jenis entitas yang perlu kamu tentukan.</p>`,
      useRaptor: 'Gunakan RAPTOR untuk meningkatkan pengambilan',
      useRaptorTip:
        'Recursive Abstractive Processing for Tree-Organized Retrieval, silakan lihat https://huggingface.co/papers/2401.18059',
      prompt: 'Prompt',
      promptTip: 'Prompt LLM yang digunakan untuk merangkum.',
      promptMessage: 'Prompt diperlukan',
      promptText: 'Silakan ringkas paragraf-paragraf berikut. Hati-hati dengan angka, jangan membuat sesuatu. Paragraf sebagai berikut:\n{cluster_content}\nKonten di atas adalah yang perlu kamu ringkas.',
      maxToken: 'Maks. Token',
      maxTokenTip: 'Jumlah token maksimum untuk merangkum.',
      maxTokenMessage: 'Maks. token diperlukan',
      threshold: 'Ambang',
      thresholdTip: 'Semakin besar ambang, semakin sedikit cluster yang akan ada.',
      thresholdMessage: 'Ambang diperlukan',
      maxCluster: 'Maks. Cluster',
      maxClusterTip: 'Jumlah cluster maksimum.',
      maxClusterMessage: 'Maks. cluster diperlukan',
      randomSeed: 'Seed Acak',
      randomSeedMessage: 'Seed acak diperlukan',
      entityTypes: 'Jenis Entitas',
    },
    chunk: {
      chunk: 'Chunk',
      bulk: 'Massal',
      selectAll: 'Pilih Semua',
      enabledSelected: 'Aktifkan yang Dipilih',
      disabledSelected: 'Nonaktifkan yang Dipilih',
      deleteSelected: 'Hapus yang Dipilih',
      search: 'Cari',
      all: 'Semua',
      enabled: 'Diaktifkan',
      disabled: 'Dinonaktifkan',
      keyword: 'Kata Kunci',
      function: 'Fungsi',
      chunkMessage: 'Silakan masukkan nilai!',
      full: 'Teks Lengkap',
      ellipse: 'Elips',
      graph: 'Graph Pengetahuan',
      mind: 'Mind Map',
    },
    chat: {
      newConversation: 'Percakapan Baru',
      createAssistant: 'Buat Asisten',
      assistantSetting: 'Pengaturan Asisten',
      promptEngine: 'Prompt Engine',
      modelSetting: 'Pengaturan Model',
      chat: 'Chat',
      newChat: 'Chat Baru',
      send: 'Kirim',
      sendPlaceholder: 'Pesan ke Asisten...',
      chatConfiguration: 'Konfigurasi Chat',
      chatConfigurationDescription:
        ' Di sini, siapkan asisten khusus untuk basis pengetahuan spesial kamu! 💕',
      assistantName: 'Nama Asisten',
      assistantNameMessage: 'Nama asisten diperlukan',
      namePlaceholder: 'misalnya. Resume Jarvis',
      assistantAvatar: 'Avatar Asisten',
      language: 'Bahasa',
      emptyResponse: 'Respons Kosong',
      emptyResponseTip: 'Jika tidak ada yang diambil dari pertanyaan pengguna di basis pengetahuan, ini akan digunakan sebagai jawaban. Jika kamu ingin LLM memberikan opini sendiri saat tidak ada yang diambil, biarkan ini kosong.',
      setAnOpener: 'Atur pembuka',
      setAnOpenerInitial: 'Hai! Saya asisten kamu, apa yang bisa saya bantu?',
      setAnOpenerTip: 'Bagaimana kamu ingin menyambut klien kamu?',
      knowledgeBases: 'Basis Pengetahuan',
      knowledgeBasesMessage: 'Silakan pilih',
      knowledgeBasesTip: 'Pilih basis pengetahuan yang terkait.',
      system: 'Sistem',
      systemInitialValue: `Kamu adalah asisten cerdas. Silakan ringkas konten basis pengetahuan untuk menjawab pertanyaan. Silakan daftar data dalam basis pengetahuan dan jawab secara detail. Jika semua konten basis pengetahuan tidak relevan dengan pertanyaan, jawabanmu harus menyertakan kalimat "Jawaban yang kamu cari tidak ditemukan di basis pengetahuan!" Jawaban perlu mempertimbangkan riwayat chat.
Berikut adalah basis pengetahuan:
{knowledge}
Di atas adalah basis pengetahuan.`,
      systemMessage: 'Silakan masukkan!',
      systemTip:
        'Instruksi yang perlu diikuti LLM saat menjawab pertanyaan, seperti desain karakter, panjang jawaban, dan bahasa jawaban, dll.',
      topN: 'Top N',
      topNTip: 'Tidak semua chunk yang skor similaritasnya di atas "ambang similaritas" akan dimasukkan ke LLM. LLM hanya bisa melihat "Top N" chunk ini.',
      variable: 'Variabel',
      variableTip: `Jika kamu menggunakan API dialog, variabel mungkin membantu kamu chat dengan klien kamu dengan strategi yang berbeda.
Variabel digunakan untuk mengisi bagian "Sistem" dalam prompt untuk memberikan petunjuk kepada LLM.
"knowledge" adalah variabel yang sangat spesial yang akan diisi dengan chunks yang diambil.
Semua variabel dalam "Sistem" harus dibungkus dengan kurung kurawal.`,
      add: 'Tambah',
      key: 'Kunci',
      optional: 'Opsional',
      operation: 'Operasi',
      model: 'Model',
      modelTip: 'Model bahasa besar untuk chat',
      modelMessage: 'Silakan pilih!',
      freedom: 'Kebebasan',
      improvise: 'Improvisasi',
      precise: 'Presisi',
      balance: 'Seimbang',
      freedomTip: '"Presisi" berarti LLM akan konservatif dan menjawab pertanyaan kamu dengan hati-hati. "Improvisasi" berarti kamu ingin LLM bicara lebih banyak dan bebas. "Seimbang" adalah antara hati-hati dan bebas.',
      temperature: 'Temperatur',
      temperatureMessage: 'Temperatur diperlukan',
      temperatureTip:
        'Parameter ini mengontrol randomness prediksi oleh model. Temperatur rendah membuat model lebih yakin dalam responsnya, sementara temperatur tinggi membuatnya lebih kreatif dan beragam.',
      topP: 'Top P',
      topPMessage: 'Top P diperlukan',
      topPTip:
        'Juga dikenal sebagai "nucleus sampling", parameter ini menetapkan ambang untuk memilih set kata yang lebih kecil untuk diambil sampelnya. Fokus pada kata-kata yang paling mungkin, memotong yang kurang mungkin.',
      presencePenalty: 'Presence Penalty',
      presencePenaltyMessage: 'Presence Penalty diperlukan',
      presencePenaltyTip:
        'Ini mendorong model untuk tidak mengulang informasi yang sama dengan memberikan penalti pada kata-kata yang sudah muncul dalam percakapan.',
      frequencyPenalty: 'Frequency Penalty',
      frequencyPenaltyMessage: 'Frequency Penalty diperlukan',
      frequencyPenaltyTip:
        'Mirip dengan presence penalty, ini mengurangi kecenderungan model untuk mengulang kata yang sama secara sering.',
      maxTokens: 'Maks. Token',
      maxTokensMessage: 'Maks. token diperlukan',
      maxTokensTip:
        'Ini menetapkan panjang maksimum output model, diukur dalam jumlah token (kata atau potongan kata).',
      quote: 'Tampilkan Kutipan',
      quoteTip: 'Apakah sumber teks asli harus ditampilkan?',
      selfRag: 'Self-RAG',
      selfRagTip: 'Silakan lihat: https://huggingface.co/papers/2310.11511',
      overview: 'API Chat Bot',
      pv: 'Jumlah pesan',
      uv: 'Jumlah pengguna aktif',
      speed: 'Kecepatan output token',
      tokens: 'Jumlah token yang dikonsumsi',
      round: 'Jumlah Interaksi Sesi',
      thumbUp: 'kepuasan pelanggan',
      preview: 'Pratinjau',
      embedded: 'Disematkan',
      serviceApiEndpoint: 'Endpoint API Layanan',
      apiKey: 'API Key',
      apiReference: 'Dokumen API',
      dateRange: 'Rentang Tanggal:',
      backendServiceApi: 'API layanan backend',
      createNewKey: 'Buat kunci baru',
      created: 'Dibuat',
      action: 'Aksi',
      embedModalTitle: 'Sematkan ke website',
      comingSoon: 'Segera Hadir',
      fullScreenTitle: 'Sematkan Penuh',
      fullScreenDescription:
        'Sematkan iframe berikut ke website kamu di lokasi yang diinginkan',
      partialTitle: 'Sematkan Parsial',
      extensionTitle: 'Ekstensi Chrome',
      tokenError: 'Silakan buat Token API terlebih dahulu!',
      searching: 'mencari...',
      parsing: 'Parsing',
      uploading: 'Mengunggah',
      uploadFailed: 'Unggah gagal',
      regenerate: 'Regenerasi',
      read: 'Baca konten',
      tts: 'Teks ke suara',
      ttsTip:
        'Untuk memutar suara menggunakan konversi suara, silakan pilih TTS (model konversi suara) di pengaturan terlebih dahulu.',
      relatedQuestion: 'Pertanyaan terkait',
      answerTitle: 'R',
      multiTurn: 'Optimasi multi-babak',
      multiTurnTip:
        'Dalam percakapan multi-round, query ke basis pengetahuan dioptimalkan. Model besar akan dipanggil untuk mengonsumsi token tambahan.',
    },
    setting: {
      profile: 'Profil',
      profileDescription: 'Perbarui foto dan detail pribadi kamu di sini.',
      password: 'Kata Sandi',
      passwordDescription:
        'Silakan masukkan kata sandi kamu saat ini untuk mengganti kata sandi.',
      model: 'Penyedia Model',
      modelDescription: 'Atur parameter model dan API Key di sini.',
      team: 'Tim',
      system: 'Sistem',
      logout: 'Keluar',
      username: 'Nama Pengguna',
      usernameMessage: 'Silakan masukkan nama pengguna kamu!',
      photo: 'Foto kamu',
      photoDescription: 'Ini akan ditampilkan di profil kamu.',
      colorSchema: 'Skema Warna',
      colorSchemaMessage: 'Silakan pilih skema warna kamu!',
      colorSchemaPlaceholder: 'pilih skema warna kamu',
      bright: 'Terang',
      dark: 'Gelap',
      timezone: 'Zona Waktu',
      timezoneMessage: 'Silakan masukkan zona waktu kamu!',
      timezonePlaceholder: 'pilih zona waktu kamu',
      email: 'Alamat Email',
      emailDescription: 'Setelah terdaftar, email tidak dapat diubah.',
      currentPassword: 'Kata sandi saat ini',
      currentPasswordMessage: 'Silakan masukkan kata sandi kamu!',
      newPassword: 'Kata sandi baru',
      newPasswordMessage: 'Silakan masukkan kata sandi kamu!',
      newPasswordDescription:
        'Kata sandi baru kamu harus lebih dari 8 karakter.',
      confirmPassword: 'Konfirmasi kata sandi baru',
      confirmPasswordMessage: 'Silakan konfirmasi kata sandi kamu!',
      confirmPasswordNonMatchMessage:
        'Kata sandi baru yang kamu masukkan tidak cocok!',
      cancel: 'Batal',
      addedModels: 'Model yang Ditambahkan',
      modelsToBeAdded: 'Model yang Akan Ditambahkan',
      addTheModel: 'Tambahkan model',
      apiKey: 'API-Key',
      apiKeyMessage:
        'Silakan masukkan API key (untuk model yang di-deploy secara lokal, abaikan ini).',
      apiKeyTip:
        'API key dapat diperoleh dengan mendaftar penyedia LLM yang sesuai.',
      showMoreModels: 'Tampilkan lebih banyak model',
      baseUrl: 'Base-Url',
      baseUrlTip:
        'Jika API key kamu dari OpenAI, abaikan saja. Penyedia perantara lainnya akan memberikan base url ini dengan API key.',
      modify: 'Ubah',
      systemModelSettings: 'Pengaturan Model Sistem',
      chatModel: 'Model Chat',
      chatModelTip:
        'Model chat default yang akan digunakan oleh semua basis pengetahuan yang baru dibuat.',
      embeddingModel: 'Model Embedding',
      embeddingModelTip:
        'Model embedding default yang akan digunakan oleh semua basis pengetahuan yang baru dibuat.',
      img2txtModel: 'Model Img2txt',
      img2txtModelTip:
        'Model multi-modul default yang akan digunakan oleh semua basis pengetahuan yang baru dibuat. Bisa mendeskripsikan gambar atau video.',
      sequence2txtModel: 'Model Sequence2txt',
      sequence2txtModelTip:
        'Model ASR default yang akan digunakan oleh semua basis pengetahuan yang baru dibuat. Gunakan model ini untuk menerjemahkan suara ke teks yang sesuai.',
      rerankModel: 'Model Rerank',
      rerankModelTip: 'Model rerank default digunakan untuk mererank chunks yang diambil oleh pertanyaan pengguna.',
      ttsModel: 'Model TTS',
      ttsModelTip:
        'Model TTS default akan digunakan untuk menghasilkan suara selama percakapan jika diminta.',
      workspace: 'Ruang Kerja',
      upgrade: 'Upgrade',
      addLlmTitle: 'Tambahkan LLM',
      modelName: 'Nama Model',
      modelID: 'ID Model',
      modelUid: 'UID Model',
      modelNameMessage: 'Silakan masukkan nama model kamu!',
      modelType: 'Tipe Model',
      modelTypeMessage: 'Silakan masukkan tipe model kamu!',
      addLlmBaseUrl: 'Base url',
      baseUrlNameMessage: 'Silakan masukkan base url kamu!',
      vision: 'Apakah mendukung Vision?',
      ollamaLink: 'Cara mengintegrasikan {{name}}',
      FishAudioLink: 'Cara menggunakan FishAudio',
      TencentCloudLink: 'Cara menggunakan TencentCloud ASR',
      volcModelNameMessage: 'Silakan masukkan nama model kamu!',
      addEndpointID: 'EndpointID dari model',
      endpointIDMessage: 'Silakan masukkan EndpointID dari model kamu',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Silakan masukkan ARK_API_KEY kamu',
      bedrockModelNameMessage: 'Silakan masukkan nama model kamu!',
      addBedrockEngineAK: 'ACCESS KEY',
      bedrockAKMessage: 'Silakan masukkan ACCESS KEY kamu',
      addBedrockSK: 'SECRET KEY',
      bedrockSKMessage: 'Silakan masukkan SECRET KEY kamu',
      bedrockRegion: 'Region AWS',
      bedrockRegionMessage: 'Silakan pilih!',
      'us-east-1': 'US East (N. Virginia)',
      'us-west-2': 'US West (Oregon)',
      'ap-southeast-1': 'Asia Pacific (Singapura)',
      'ap-northeast-1': 'Asia Pacific (Tokyo)',
      'eu-central-1': 'Eropa (Frankfurt)',
      'us-gov-west-1': 'AWS GovCloud (US-West)',
      'ap-southeast-2': 'Asia Pacific (Sydney)',
      addHunyuanSID: 'Hunyuan Secret ID',
      HunyuanSIDMessage: 'Silakan masukkan Secret ID kamu',
      addHunyuanSK: 'Hunyuan Secret Key',
      HunyuanSKMessage: 'Silakan masukkan Secret Key kamu',
      addTencentCloudSID: 'TencentCloud Secret ID',
      TencentCloudSIDMessage: 'Silakan masukkan Secret ID kamu',
      addTencentCloudSK: 'TencentCloud Secret Key',
      TencentCloudSKMessage: 'Silakan masukkan Secret Key kamu',
      SparkModelNameMessage: 'Silakan pilih model Spark',
      addSparkAPIPassword: 'Spark APIPassword',
      SparkAPIPasswordMessage: 'silakan masukkan APIPassword kamu',
      addSparkAPPID: 'Spark APPID',
      SparkAPPIDMessage: 'silakan masukkan APPID kamu',
      addSparkAPISecret: 'Spark APISecret',
      SparkAPISecretMessage: 'silakan masukkan APISecret kamu',
      addSparkAPIKey: 'Spark APIKey',
      SparkAPIKeyMessage: 'silakan masukkan APIKey kamu',
      yiyanModelNameMessage: 'Silakan masukkan nama model',
      addyiyanAK: 'yiyan API KEY',
      yiyanAKMessage: 'Silakan masukkan API KEY kamu',
      addyiyanSK: 'yiyan Secret KEY',
      yiyanSKMessage: 'Silakan masukkan Secret KEY kamu',
      FishAudioModelNameMessage:
        'Silakan beri nama model sintesis suara kamu',
      addFishAudioAK: 'Fish Audio API KEY',
      addFishAudioAKMessage: 'Silakan masukkan API KEY kamu',
      addFishAudioRefID: 'FishAudio Reference ID',
      addFishAudioRefIDMessage:
        'Silakan masukkan Reference ID (biarkan kosong untuk menggunakan model default).',
      GoogleModelIDMessage: 'Silakan masukkan ID model kamu!',
      addGoogleProjectID: 'Project ID',
      GoogleProjectIDMessage: 'Silakan masukkan Project ID kamu',
      addGoogleServiceAccountKey:
        'Service Account Key (Biarkan kosong jika kamu menggunakan Application Default Credentials)',
      GoogleServiceAccountKeyMessage:
        'Silakan masukkan Google Cloud Service Account Key dalam format base64',
      addGoogleRegion: 'Region Google Cloud',
      GoogleRegionMessage: 'Silakan masukkan Region Google Cloud kamu',
      modelProvidersWarn:
        'Silakan tambahkan baik model embedding dan LLM di <b>Settings > Model providers</b> terlebih dahulu.',
    },
    message: {
      registered: 'Terdaftar!',
      logout: 'keluar',
      logged: 'terlogin!',
      pleaseSelectChunk: 'Silakan pilih bagian!',
      modified: 'Diubah',
      created: 'Dibuat',
      deleted: 'Dihapus',
      renamed: 'Diganti nama',
      operated: 'Dioperasikan',
      updated: 'Diperbarui',
      uploaded: 'Diunggah',
      200: 'Server berhasil mengembalikan data yang diminta.',
      201: 'Data berhasil dibuat atau diubah.',
      202: 'Permintaan telah dimasukkan ke antrean latar belakang (tugas asinkron).',
      204: 'Data berhasil dihapus.',
      400: 'Ada kesalahan dalam permintaan yang diajukan, dan server tidak membuat atau mengubah data.',
      401: 'Pengguna tidak memiliki izin (token, username, atau password salah).',
      403: 'Pengguna sudah terotentikasi, tapi akses ditolak.',
      404: 'Permintaan untuk data yang tidak ada, dan server tidak melakukan operasi.',
      406: 'Format yang diminta tidak tersedia.',
      410: 'Sumber daya yang diminta telah dihapus permanen dan tidak akan tersedia lagi.',
      422: 'Terjadi kesalahan validasi saat membuat objek.',
      500: 'Terjadi kesalahan di server, silakan cek server.',
      502: 'Kesalahan gateway.',
      503: 'Layanan tidak tersedia dan server sedang kelebihan beban sementara atau dalam perawatan.',
      504: 'Waktu tunggu gateway habis.',
      requestError: 'Kesalahan permintaan',
      networkAnomalyDescription:
        'Ada masalah dengan jaringan Anda dan Anda tidak bisa terhubung ke server.',
      networkAnomaly: 'masalah jaringan',
      hint: 'petunjuk',
    },
    fileManager: {
      name: 'Nama',
      uploadDate: 'Tanggal Unggah',
      knowledgeBase: 'Basis Pengetahuan',
      size: 'Ukuran',
      action: 'Aksi',
      addToKnowledge: 'Tautkan ke Basis Pengetahuan',
      pleaseSelect: 'Silakan pilih',
      newFolder: 'Folder Baru',
      file: 'File',
      uploadFile: 'Unggah File',
      directory: 'Direktori',
      uploadTitle: 'Klik atau seret file ke area ini untuk mengunggah',
      uploadDescription:
        'Mendukung unggahan tunggal atau massal. Dilarang keras mengunggah data perusahaan atau file terlarang lainnya.',
      local: 'Unggah lokal',
      s3: 'Unggah S3',
      preview: 'Pratinjau',
      fileError: 'Kesalahan file',
      uploadLimit:
        'Ukuran file tidak boleh melebihi 10M, dan jumlah total file tidak boleh melebihi 128',
      destinationFolder: 'Folder tujuan',
    },
    flow: {
      cite: 'Sebutkan',
      citeTip: 'petunjuk sebutkan',
      name: 'Nama',
      nameMessage: 'Silakan masukkan nama',
      description: 'Deskripsi',
      examples: 'Contoh',
      to: 'Ke',
      msg: 'Pesan',
      messagePlaceholder: 'pesan',
      messageMsg: 'Silakan masukkan pesan atau hapus bidang ini.',
      addField: 'Tambah bidang',
      addMessage: 'Tambah pesan',
      loop: 'Loop',
      loopTip:
        'Loop adalah batas maksimal jumlah pengulangan komponen saat ini. Jika jumlah loop melebihi nilai loop, berarti komponen tidak bisa menyelesaikan tugas saat ini. Silakan optimalkan ulang agen.',
      yes: 'Ya',
      no: 'Tidak',
      key: 'Kunci',
      componentId: 'ID Komponen',
      add: 'Tambah',
      operation: 'Operasi',
      run: 'Jalankan',
      save: 'Simpan',
      title: 'ID:',
      beginDescription: 'Di sinilah alur dimulai.',
      answerDescription: `Komponen yang menjadi antarmuka antara manusia dan bot, menerima input pengguna dan menampilkan respons agen.`,
      retrievalDescription: `Komponen yang mengambil informasi dari basis pengetahuan tertentu dan mengembalikan 'Respons kosong' jika tidak ditemukan informasi. Pastikan basis pengetahuan yang benar dipilih.`,
      generateDescription: `Komponen yang meminta LLM untuk menghasilkan respons. Pastikan prompt diatur dengan benar.`,
      categorizeDescription: `Komponen yang menggunakan LLM untuk mengklasifikasikan input pengguna ke dalam kategori yang telah ditentukan. Pastikan Anda menentukan nama, deskripsi, dan contoh untuk setiap kategori, beserta komponen selanjutnya yang sesuai.`,
      relevantDescription: `Komponen yang menggunakan LLM untuk menilai apakah output sebelumnya relevan dengan pertanyaan terbaru pengguna. Pastikan Anda menentukan komponen selanjutnya untuk setiap hasil penilaian.`,
      rewriteQuestionDescription: `Komponen yang memperbaiki pertanyaan pengguna jika gagal mengambil informasi relevan dari basis pengetahuan. Proses ini diulang hingga batas maksimal pengulangan tercapai. Pastikan hulu-nya adalah 'Relevan' dan hilir-nya adalah 'Pengambilan'.`,
      messageDescription:
        "Komponen yang mengirimkan pesan statis. Jika ada beberapa pesan, akan dipilih salah satunya secara acak untuk dikirim. Pastikan hilir-nya adalah 'Jawab', komponen antarmuka.",
      keywordDescription: `Komponen yang mengambil hasil pencarian Top N dari input pengguna. Pastikan nilai TopN diatur dengan benar sebelum digunakan.`,
      switchDescription: `Komponen yang mengevaluasi kondisi berdasarkan output komponen sebelumnya dan mengarahkan alur eksekusi sesuai. Ini memungkinkan logika percabangan yang kompleks dengan mendefinisikan kasus dan menentukan tindakan untuk setiap kasus atau tindakan default jika tidak ada kondisi yang terpenuhi.`,
      wikipediaDescription: `Komponen ini digunakan untuk mendapatkan hasil pencarian dari wikipedia.org. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu disesuaikan.`,
      promptText: `Silakan rangkum paragraf berikut. Berhati-hatilah dengan angka, jangan mengarang. Paragraf berikut:
            {input}
      Di atas adalah konten yang perlu Anda rangkum.`,
      createGraph: 'Buat agen',
      createFromTemplates: 'Buat dari templat',
      retrieval: 'Pengambilan',
      generate: 'Hasilkan',
      answer: 'Interaksi',
      categorize: 'Kategorikan',
      relevant: 'Relevan',
      rewriteQuestion: 'Tulis ulang',
      rewrite: 'Tulis ulang',
      begin: 'Mulai',
      message: 'Pesan',
      blank: 'Kosong',
      createFromNothing: 'Buat agen Anda dari awal',
      addItem: 'Tambah Item',
      addSubItem: 'Tambah Sub Item',
      nameRequiredMsg: 'Nama diperlukan',
      nameRepeatedMsg: 'Nama tidak boleh diulang',
      keywordExtract: 'Kata Kunci',
      keywordExtractDescription: `Komponen ini digunakan untuk mengekstrak kata kunci dari pertanyaan pengguna. Top N menentukan jumlah kata kunci yang perlu diekstrak.`,
      baidu: 'Baidu',
      baiduDescription: `Komponen ini digunakan untuk mendapatkan hasil pencarian dari www.baidu.com. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu disesuaikan.`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari www.duckduckgo.com. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu disesuaikan.',
      channel: 'Saluran',
      channelTip: `Lakukan pencarian teks atau pencarian berita pada input komponen`,
      text: 'Teks',
      news: 'Berita',
      messageHistoryWindowSize: 'Ukuran jendela pesan',
      messageHistoryWindowSizeTip:
        'Ukuran jendela riwayat percakapan yang perlu dilihat oleh LLM. Semakin besar semakin baik. Tapi hati-hati dengan panjang konten maksimal LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      email: 'Email',
      emailTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://pubmed.ncbi.nlm.nih.gov/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu disesuaikan. Email adalah bidang yang wajib diisi.',
      arXiv: 'ArXiv',
      arXivTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://arxiv.org/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu disesuaikan.',
      sortBy: 'Urutkan menurut',
      submittedDate: 'Tanggal pengiriman',
      lastUpdatedDate: 'Tanggal pembaruan terakhir',
      relevance: 'Relevansi',
      google: 'Google',
      googleTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://www.google.com/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N dan kunci API SerpApi menentukan jumlah hasil pencarian yang perlu disesuaikan.',
      bing: 'Bing',
      bingTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://www.bing.com/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N dan Bing Subscription-Key menentukan jumlah hasil pencarian yang perlu disesuaikan.',
      apiKey: 'Kunci API',
      country: 'Negara',
      language: 'Bahasa',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://scholar.google.com/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu disesuaikan.',
      yearLow: 'Tahun mulai',
      yearHigh: 'Tahun akhir',
      patents: 'Paten',
      data: 'Data',
      deepL: 'DeepL',
      deepLDescription:
        'Komponen ini digunakan untuk mendapatkan terjemahan dari https://www.deepl.com/. Biasanya, ini menyediakan hasil terjemahan yang lebih spesifik.',
      authKey: 'Kunci otentikasi',
      sourceLang: 'Bahasa sumber',
      targetLang: 'Bahasa target',
      gitHub: 'GitHub',
      githubDescription:
        'Komponen ini digunakan untuk mencari repositori dari https://github.com/. Top N menentukan jumlah hasil pencarian yang perlu disesuaikan.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'Komponen ini digunakan untuk mendapatkan terjemahan dari https://fanyi.baidu.com/. Biasanya, ini menyediakan hasil terjemahan yang lebih spesifik.',
      appid: 'App id',
      secretKey: 'Kunci rahasia',
      domain: 'Domain',
      transType: 'Jenis terjemahan',
      baiduSecretKeyOptions: {
        translate: 'Terjemahan umum',
        fieldtranslate: 'Terjemahan bidang',
      },
      baiduDomainOptions: {
        it: 'Teknologi informasi',
        finance: 'Keuangan dan ekonomi',
        machinery: 'Manufaktur mesin',
        senimed: 'Biomedis',
        novel: 'Sastra online',
        academic: 'Makalah akademik',
        aerospace: 'Dirgantara',
        wiki: 'Humaniora dan ilmu sosial',
        news: 'Berita dan informasi',
        law: 'Hukum dan peraturan',
        contract: 'Kontrak',
      },
      baiduSourceLangOptions: {
        auto: 'Deteksi otomatis',
        zh: 'Cina',
        id: 'Indonesia',
        en: 'Inggris',
        yue: 'Kanton',
        wyw: 'Cina Klasik',
        jp: 'Jepang',
        kor: 'Korea',
        fra: 'Perancis',
        spa: 'Spanyol',
        th: 'Thailand',
        ara: 'Arab',
        ru: 'Rusia',
        pt: 'Portugis',
        de: 'Jerman',
        it: 'Italia',
        el: 'Yunani',
        nl: 'Belanda',
        pl: 'Polandia',
        bul: 'Bulgaria',
        est: 'Estonia',
        dan: 'Denmark',
        fin: 'Finlandia',
        cs: 'Ceko',
        rom: 'Rumania',
        slo: 'Slovenia',
        swe: 'Swedia',
        hu: 'Hungaria',
        cht: 'Cina Tradisional',
        vie: 'Vietnam',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'Komponen ini digunakan untuk mendapatkan informasi cuaca dari https://www.qweather.com/. Anda bisa mendapatkan cuaca, indeks, dan kualitas udara.',
      lang: 'Bahasa',
      type: 'Jenis',
      webApiKey: 'Kunci API Web',
      userType: 'Jenis pengguna',
      timePeriod: 'Periode waktu',
      qWeatherLangOptions: {
        zh: 'Cina Sederhana',
        id: 'Indonesia',
        'zh-hant': 'Cina Tradisional',
        en: 'Inggris',
        de: 'Jerman',
        es: 'Spanyol',
        fr: 'Perancis',
        it: 'Italia',
        ja: 'Jepang',
        ko: 'Korea',
        ru: 'Rusia',
        hi: 'Hindi',
        th: 'Thailand',
        ar: 'Arab',
        pt: 'Portugis',
        bn: 'Benggala',
        ms: 'Melayu',
        nl: 'Belanda',
        el: 'Yunani',
        la: 'Latin',
        sv: 'Swedia',
        id: 'Indonesia',
        pl: 'Polandia',
        tr: 'Turki',
        cs: 'Ceko',
        et: 'Estonia',
        vi: 'Vietnam',
        fil: 'Filipina',
        fi: 'Finlandia',
        he: 'Ibrani',
        is: 'Islandia',
        nb: 'Norwegia',
      },
      qWeatherTypeOptions: {
        weather: 'Prakiraan Cuaca',
        indices: 'Indeks kehidupan cuaca',
        airquality: 'Kualitas udara',
      },
      qWeatherUserTypeOptions: {
        free: 'Pelanggan gratis',
        paid: 'Pelanggan berbayar',
      },
      qWeatherTimePeriodOptions: {
        now: 'Sekarang',
        '3d': '3 hari',
        '7d': '7 hari',
        '10d': '10 hari',
        '15d': '15 hari',
        '30d': '30 hari',
      },
      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'Komponen ini melakukan query hasil dari database relasional yang sesuai melalui pernyataan SQL. Mendukung MySQL, PostgreSQL, MariaDB.',
      dbType: 'Jenis Database',
      database: 'Database',
      username: 'Nama pengguna',
      host: 'Host',
      port: 'Port',
      password: 'Kata sandi',
      switch: 'Ganti',
      logicalOperator: 'Operator logika',
      switchOperatorOptions: {
        equal: 'sama dengan',
        notEqual: 'tidak sama dengan',
        gt: 'Lebih besar dari',
        ge: 'Lebih besar atau sama dengan',
        lt: 'Lebih kecil dari',
        le: 'Lebih kecil atau sama dengan',
        contains: 'Mengandung',
        notContains: 'Tidak mengandung',
        startWith: 'Dimulai dengan',
        endWith: 'Diakhiri dengan',
        empty: 'Kosong',
        notEmpty: 'Tidak kosong',
      },
      switchLogicOperatorOptions: {
        and: 'Dan',
        or: 'Atau',
      },
      operator: 'Operator',
      value: 'Nilai',
      useTemplate: 'Gunakan templat ini',
      wenCai: 'WenCai',
      queryType: 'Jenis query',
      wenCaiDescription:
        'Komponen ini dapat digunakan untuk memperoleh informasi di berbagai bidang keuangan, termasuk tapi tidak terbatas pada saham, dana, dll...',
      wenCaiQueryTypeOptions: {
        stock: 'saham',
        zhishu: 'indeks',
        fund: 'dana',
        hkstock: 'saham Hong Kong',
        usstock: 'pasar saham AS',
        threeboard: 'Pasar OTC baru',
        conbond: 'Obligasi konvertibel',
        insurance: 'asuransi',
        futures: 'futures',
        lccp: 'Pembiayaan',
        foreign_exchange: 'Mata uang asing',
      },
      akShare: 'AkShare',
      akShareDescription:
        'Komponen ini dapat digunakan untuk memperoleh informasi berita untuk saham yang bersangkutan dari situs Eastmoney.',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'Komponen ini melakukan query informasi tentang perusahaan berdasarkan simbol ticker yang diberikan.',
      info: 'Info',
      history: 'Sejarah',
      financials: 'Keuangan',
      balanceSheet: 'Neraca',
      cashFlowStatement: 'Laporan arus kas',
      jin10: 'Jin10',
      jin10Description:
        'Komponen ini dapat digunakan untuk mengakses informasi di sektor keuangan dari Platform Terbuka Jin10, termasuk berita cepat, kalender, kutipan, referensi.',
      flashType: 'Jenis flash',
      filter: 'Filter',
      contain: 'Mengandung',
      calendarType: 'Jenis kalender',
      calendarDatashape: 'Bentuk data kalender',
      symbolsDatatype: 'Tipe data simbol',
      symbolsType: 'Tipe simbol',
      jin10TypeOptions: {
        flash: 'Berita Cepat',
        calendar: 'Kalender',
        symbols: 'kutipan',
        news: 'referensi',
      },
      jin10FlashTypeOptions: {
        "1": 'Berita Pasar',
        "2": 'Berita Futures',
        "3": 'Berita AS-Hong Kong',
        "4": 'Berita Saham A',
        "5": 'Berita Komoditas & Forex',
      },
      jin10CalendarTypeOptions: {
        cj: 'Kalender Data Makroekonomi',
        qh: 'Kalender Futures',
        hk: 'Kalender Pasar Saham Hong Kong',
        us: 'Kalender Pasar Saham AS',
      },
      jin10CalendarDatashapeOptions: {
        data: 'Data',
        event: 'Peristiwa',
        holiday: 'Libur',
      },
      jin10SymbolsTypeOptions: {
        GOODS: 'Kutipan Komoditas',
        FOREX: 'Kutipan Forex',
        FUTURE: 'Kutipan Pasar Internasional',
        CRYPTO: 'Kutipan Cryptocurrency',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: 'Daftar Komoditas',
        quotes: 'Kutipan Pasar Terbaru',
      },
      concentrator: 'Konsentrator',
      concentratorDescription:
        'Komponen ini dapat digunakan untuk menghubungkan beberapa komponen hilir. Menerima input dari komponen hulu dan meneruskannya ke setiap komponen hilir.',
    },
    footer: {
      profile: 'Hak cipta dilindungi @ React',
    },
    layout: {
      file: 'file',
      knowledge: 'pengetahuan',
      chat: 'obrolan',
    },
  },
};
